/* eslint-disable notice/notice */

// eslint-disable-next-line import/no-webpack-loader-syntax,import/no-unresolved
import { useScope as useI18nScope } from '@canvas/i18n';
import React, {useState} from 'react'
import ReactDOM from 'react-dom'
import {useMediaSet} from 'use-media-set'
import ready from '@instructure/ready'
import {Text} from '@instructure/ui-text'
import {Link} from '@instructure/ui-link'
import {Img} from '@instructure/ui-img'
import {Flex} from '@instructure/ui-flex'
import {View} from '@instructure/ui-view'
import {Button} from '@instructure/ui-buttons'
import CanvasSelect from '@canvas/instui-bindings/react/Select'
import confusedPandaUrl from '@canvas/images/ConfusedPanda.svg'
import {arrayOf, func, string} from 'prop-types'

const I18n = useI18nScope('disambiguate');

const FREE_FOR_TEACHER_URL = 'https://www.instructure.com/canvas/login/free-for-teacher'
const TRY_CANVAS_URL = 'https://www.instructure.com/canvas/try-canvas'
const INSTRUCTURE_URL = 'https://www.instructure.com'

function redirectToLogin() {
  window.location = FREE_FOR_TEACHER_URL
}

function redirectToSignup() {
  window.location = TRY_CANVAS_URL
}

/*
 * This is when we have to give the user a choice between which of multiple
 * Canvas hosts to use for the Community account creation
 */
const HostPicker = ({hostList, setOtherLogin}) => {
  const [host, setHost] = useState(null)
  const options = hostList.map(([name, url]) => (
    <CanvasSelect.Option key={name} id={name} value={url}>
      {name}
    </CanvasSelect.Option>
  ))
  options.push(
    <CanvasSelect.Option key="other" id="other" value="__other__">
      {I18n.t('Other')}
    </CanvasSelect.Option>
  )

  function onChange(_e, value) {
    setHost(value)
  }

  function onSelect() {
    if (host === '__other__') {
      setOtherLogin(true)
    } else {
      window.location = host
    }
  }

  return (
    <>
      <Flex.Item textAlign="center" padding="x-small">
        <Text size="large">{I18n.t('Which Canvas account would you like to use to log in?')}</Text>
      </Flex.Item>
      <Flex.Item padding="small">
        <Flex justifyItems="center" direction="row">
          <Flex.Item size="300px" padding="small">
            <CanvasSelect
              value={host}
              onChange={onChange}
              label=""
              placeholder={I18n.t('Choose Canvas Account…')}
            >
              {options}
            </CanvasSelect>
          </Flex.Item>
          <Flex.Item padding="small">
            <Button color="primary" interaction={host ? 'enabled' : 'disabled'} onClick={onSelect}>
              {I18n.t('Go')}
            </Button>
          </Flex.Item>
        </Flex>
      </Flex.Item>
    </>
  )
}

HostPicker.propTypes = {
  hostList: arrayOf(arrayOf(string)).isRequired,
  setOtherLogin: func.isRequired
}

const innerBoxProps = {
  borderColor: 'primary',
  borderWidth: 'small',
  borderRadius: 'medium',
  padding: 'small large',
  margin: 'none none medium none',
  textAlign: 'center',
  as: 'div'
}

const buttonProps = {
  size: 'medium',
  margin: 'small none none none'
}

/*
 * This is for when no Canvas hosts appear in the browser cookies,
 * so we give the user a choice of either going to the FFT login
 * page, or to create a Canvas account.
 */
const MustLogIn = () => {
  const mediaState = useMediaSet({
    wide: {width: '750..'}
  })
  const direction = mediaState.has('wide') ? 'row' : 'column'
  const viewMinHeight = mediaState.has('wide') ? '82px' : 'auto'

  return (
    <>
      <Flex.Item textAlign="center" padding="x-small">
        <Text size="large">
          {I18n.t('To join the Canvas Community, you must first have a Canvas Account.')}
        </Text>
      </Flex.Item>
      <Flex.Item>
        <Flex direction={direction} justifyItems="space-around" padding="large">
          <Flex.Item size="40%">
            <View {...innerBoxProps}>
              <View as="div" minHeight={viewMinHeight}>
                <Text size="large" as="h2" weight="normal">
                  {I18n.t('Already have a Canvas Account?')}
                </Text>
              </View>
              <Button {...buttonProps} onClick={redirectToLogin}>
                {I18n.t('Log In')}
              </Button>
            </View>
          </Flex.Item>
          <Flex.Item size="40%">
            <View {...innerBoxProps}>
              <View as="div" minHeight={viewMinHeight}>
                <Text size="large" as="h2" weight="normal">
                  {I18n.t('Try out Canvas for Free!')}
                </Text>
              </View>
              <Button {...buttonProps} onClick={redirectToSignup}>
                {I18n.t('Sign Up')}
              </Button>
            </View>
          </Flex.Item>
        </Flex>
      </Flex.Item>
    </>
  )
}

/*
 * Display main content, and choose which experience the user should see.
 */
const Disambiguate = ({provider, hostList}) => {
  const [otherLogin, setOtherLogin] = useState(hostList.length === 0)
  const hostPickerProps = {hostList, setOtherLogin}
  const sorryMsg = provider
    ? I18n.t('Sorry, but we couldnʼt automatically log you into %{provider}.', {provider})
    : I18n.t('Sorry, but we couldnʼt automatically log you into the Community.')
  return (
    <Flex justifyItems="center" direction="column">
      <Flex.Item textAlign="center" margin="medium none">
        <Img src={confusedPandaUrl} alt={I18n.t('Confused Panda questioning what to do next')} />
      </Flex.Item>
      <Flex.Item textAlign="center" padding="x-small">
        <Text size="x-large">{sorryMsg}</Text>
      </Flex.Item>
      {otherLogin ? <MustLogIn /> : <HostPicker {...hostPickerProps} />}
    </Flex>
  )
}

Disambiguate.propTypes = {
  hostList: arrayOf(arrayOf(string)).isRequired,
  provider: string.isRequired
}

/*
 * Shows the "Powered by Instructure" logo on the footer of the page.
 */
const PageFooter = ({imagePath}) => (
  <Link href={INSTRUCTURE_URL}>
    <Img src={imagePath} alt={I18n.t('Powered By Instructure')} />
  </Link>
)

PageFooter.propTypes = {
  imagePath: string.isRequired
}

ready(function() {
  const {footerLogoPath, canvasHosts, providerName} = ENV.DISAMBIGUATE
  const container = document.getElementById('content')
  const footer = document.getElementById('footer')
  ReactDOM.render(<Disambiguate hostList={canvasHosts} provider={providerName} />, container)
  ReactDOM.render(<PageFooter imagePath={footerLogoPath} />, footer)
})
